.text-light {
  font: var(--font-light)
}
.text-regular {
  font: var(--font-regular)
}
.text-special {
  font: var(--font-special)
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  width: 100%;
  height: 100%;
}
*::-webkit-scrollbar {
  display: none !important;
}
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.loader {
  width: 120px;
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #fff;
  filter: blur(5px) contrast(10) hue-rotate(60deg);
  mix-blend-mode: darken;
}
.loader:before,
.loader:after {
  content: "";
  width: 40px;
  border-radius: 50%;
  background: rgb(var(--color-primary));
  animation: l3 1s infinite alternate;
}
.loader:after {
  --s:-1;
}
@keyframes l3{
  90%,100% {transform: translate(calc(var(--s,1)*30px))}
}