@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

body {
  font: var(--font-regular);
}

.custom-btn-secondary {
  background-color: rgb(var(--color-secondary));
  color: #ffffff;
  border: 4px solid rgb(var(--color-secondary));
}

.custom-btn-white {
  background-color: #ffffff;
  color: rgb(var(--color-secondary));
}

.custom-btn-white-with-border {
  background-color: #ffffff;
  color: rgb(var(--color-secondary));
  border: 4px solid rgb(var(--color-secondary));
}

.custom-btn-white {
  background-color: #ffffff;
  color: rgb(var(--color-secondary));
  border: 1px solid rgb(var(--color-secondary));
}

.custom-btn-disabled {
  background-color: gray;
  color: #ffffff;
  border: 2px solid rgb(var(--color-secondary));
}

.scrollbar::-webkit-scrollbar {
  display: block !important;
}
.scrollbar {
  -ms-overflow-style: auto;
  scrollbar-width: auto;
}

.scrollbar::-webkit-scrollbar {
  width: 15px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}